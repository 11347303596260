import {styled, useTheme} from "@mui/material/styles";
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import logo from "../images/transparent-logo.png";
import logo1 from "../images/transparent-logo1.png";
import logo2 from "../images/transparent-logo2.png";
import logo3 from "../images/transparent-logo3.png";
import {FixturesOverview} from "./FixturesOverview";
import {Export} from "./Export";
import {fetchFromApi} from "../helpers/fetchFromApi";
import {useEffect, useState} from "react";
import {TeamStrengthDialog} from "./TeamStrengthDialog";
import {AppBar} from "../styled-components/AppBar";
import {LeagueStandingsDialog} from "./LeagueStandingsDialog";
import {UserDialog} from "./UserDialog";
import {extractFlag} from "../components/Help";
import '../parts/animation-button.css';
import {HtmlTooltip} from "../styled-components/HtmlTooltip";
import YoutubeModal from "./YoutubeModal";

const drawerWidth = 240;

const Main = styled("main", {shouldForwardProp: (prop) => prop !== "open"})(
  ({theme, open}) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const strs = [
  "bWFyY3VzLWJiZ0B3ZWIuZGU=",
  "cGF0cmlrLnNjaG1pZHRAeW1haWwuY29t",
  "dGlwc3RlcmNvbXBhbnlAZ214LmRl",
  "dG9ta2x1ZXNlbmVyQHQtb25saW5lLmRl",
  "ZGVubmlzb21lbHRzY2hlbmtvQHdlYi5kZQ==",
  "emFzdGVyNjBAb25saW5lLmRl",
  "Zi5zcGllZ2VsQGhvdG1haWwuZGU=",
  "bWhlaWRidWVjaGVsQG1haWwuZGU=",
  "aGVpbWFubi5oQGFvbC5jb20=",
  "c3RlZmZlbndlaWNrLjFAZ214LmRl",
  "dGFzY2hlbGVlci50bWxAZ29vZ2xlbWFpbC5jb20=",
  "eWFubmljZ295bkBnbWFpbC5jb20=",
  "amVucy5zY2hlbmtlQGdvb2dsZW1haWwuY29t",
  "a3Vuei5zdDNmYW5AZ21haWwuY29t",
  "eWFubmljZ295bjU3QGdtYWlsLmNvbQ==",
  "bWlvczc4QGdteC5kZQ==",
  "dW5kZXJncm91bmRzYWFyQGFvbC5jb20=",
  "dGhvbWFzLmJ1c2xAZ21haWwuY29t",
  "dHJhaW5lcjhAZ214LmRl",
  "YnNiYXNzYm9vc3RlZEBnbWFpbC5jb20=",
  "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==",
  "ZnJhbmNvdGFmdXJvQGdteC5kZQ==",
  "bWFyY3NraXBwZXJAd2ViLmRl",
  "cGFzY2FsYm9yeXNAZ21haWwuY29t",
  "MDdsZWh3YWxkMTJAd2ViLmRl",
  "YmVuYmFsazIuMEBnbWFpbC5jb20=",
  "YmVuYmFsazIuMEBnbWFpbC5jb20=",
  "bWFyaXVzLm1laXNidXJnQGdtYWlsLmNvbQ==",
  "cXVlbnRpbi5oZXJyZW5icnVlY2tAZ214Lm5ldA==",
  "ZGF2aWQuYm90c2NoZW5AZ21haWwuY29t",
  "anVhbm1hc3NhbmV0QGdteC5kZQ==",
  "cm9iZGVtYXJjbzk1QGxpYmVyby5pdA==",
  "cy5tb2VsbGVyNzlAZ21haWwuY29t",
  "Y2hyaXN0aWFuaGlsY2hlckBmcmVlbmV0LmRl",
  "bXIubW9saUB3ZWIuZGU=",
  "bGllc2tlLmJlbml0ekB0LW9ubGluZS5kZQ==",
  "ZGVtb2huQG91dGxvb2suZGU="
];

export function LeagueDrawer() {
  const theme = useTheme();

  const [userExport, setUserExport] = useState();
  const [user, setUser] = useState();

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);

  };
  const handleDrawerClose = () => {
    setOpen(false);

  };

  async function getLeages() {
    try {
      const request = await fetchFromApi("fetch-data/leagues?include=country");
      const {data: leagues} = await request.json();
      return leagues;
    } catch (e) {
      return [];
    }
  }

  async function getUser() {
    try {
      return await (await fetchFromApi("get-user")).json();
    } catch (e) {
      return {};
    }
  }

  async function fetchData() {
    const leagues = await getLeages();

    let user = await getUser();
    let userId = user.id;
    setUser(user);

    var ls = []

    if (typeof user.registrationDate === "undefined") {
      ls = ["Bundesliga", "2. Bundesliga", "3. Liga", "Premier League", "Championship", "League One", "League Two", "Veikkausliiga", "National League", "Eredivisie", "Admiral Bundesliga", "Pro League", "Veikkausliga", "Ligue 1", "Ligue 2", "Serie A", "Serie B", "Eliteserien", "Liga Portugal", "Premiership", "La Liga", "La Liga 2", "Super League", "Super Lig", "Major League Soccer", "J-League", "Superliga"]
    } else {
      ls = ["Bundesliga", "2. Bundesliga", "Premier League", "La Liga", "Serie A", "Ligue 1", "Admiral Bundesliga", "Super League", "Eredivisie", "Pro League"]
    }
    let freeLeagues = leagues.filter(l => ls.includes(l.name));

    if (strs.includes(btoa(userId)) || ["cGF1bGVAcGF1bGUtd2V0dGVuLmRl"].includes(btoa(userId))) {
      setLeagues(leagues);
    } else {
      var country_id = 35376
      freeLeagues = freeLeagues.filter(l => l.country_id !== country_id);
      setLeagues(freeLeagues);
    }
    //setLeagues(leagues);
  }

  useEffect(() => {
    fetchData();
  }, []);
  const [leagues, setLeagues] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [league, setLeague] = useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  function handleSelect(league) {
    setLeague(league);

  }

  const handleClose = () => {
    setAnchorEl(null);

  };
  const [anchorStandingsEl, setAnchorStandingsEl] = useState(null);
  const menuStandingsOpen = Boolean(anchorStandingsEl);
  const [standing, setStanding] = useState();
  const [userToken, setUserToken] = useState();

  const handleStandingsClick = (event) => {
    setAnchorStandingsEl(event.currentTarget);
  };

  function handleStandingsSelect(league) {
    setStanding(league);
  }

  const handleStandingsClose = () => {
    setAnchorStandingsEl(null);
  };

  async function fetchUser() {
    try {
      const user = await getUser();
      setUser(user);
    } catch (e) {
      window.location.pathname = "sign-in"
    }
  }

  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  function handleSetAllUsers(value) {
    setAllUsers(value);
  }

  function handleSetUserDialogOpen(value) {
    setUserDialogOpen(value);
    if (!value) {
      fetchUser();
    }
  }

  function getLogo() {
    var userId = "";
    if (typeof user === "undefined") {
      return logo
    } else {
      userId = user.id;
    }
    if (strs.includes(btoa(userId))) {
      return logo2;
    }
    if (["cGF1bGVAcGF1bGUtd2V0dGVuLmRl"].includes(btoa(userId))) {
      return logo3;
    }
    return logo1;
  }

  let teamSettings = <Button
    id="basic-button"
    color="inherit"
    aria-controls={menuOpen ? "basic-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={menuOpen ? "true" : undefined}
    onClick={handleClick}
    sx={{ml: 2}}
  >
    Team Settings
  </Button>;
  let tooltipTeamSettings = <HtmlTooltip title={
    <YoutubeModal title="" youtubeId="NgR8wr4fUsI"></YoutubeModal>
  }>
    {teamSettings}
  </HtmlTooltip>;
  let standings = <Button
    id="basic-button"
    color="inherit"
    aria-controls={menuStandingsOpen ? "basic-menu" : undefined}
    aria-haspopup="true"
    aria-expanded={menuStandingsOpen ? "true" : undefined}
    onClick={handleStandingsClick}
    sx={{ml: 2}}
  >
    Standings
  </Button>;
  let tooltipStandings = <HtmlTooltip title={
    <YoutubeModal title="" youtubeId="KUVuwg2TDA4"></YoutubeModal>
  }>
    {standings}
  </HtmlTooltip>;
  let help = <Button href="help" target="_blank" color="inherit" sx={{ml: 2}}>
    Help
  </Button>;
  let tooltipHelp = <HtmlTooltip title={
    <YoutubeModal title="" youtubeId="B_XyUFv99Qk"></YoutubeModal>
  }>
    {help}
  </HtmlTooltip>;
  let docs = <Button
    href="https://docs.betty.works"
    target="_blank"
    color="inherit"
    sx={{ml: 2}}
  >
    Docs
  </Button>;
  let tooltipDocs = <HtmlTooltip title={
    <YoutubeModal title="" youtubeId="eruPAUeu5ns"></YoutubeModal>
  }>
    {docs}
  </HtmlTooltip>;
  let pricing = <Button
    href="https://plans.betty.works"
    target="_blank"
    color="inherit"
    sx={{ml: 2}}
  >
    Pricing
  </Button>;
  let tooltipPricing = <HtmlTooltip title={
    <YoutubeModal title="" youtubeId="VJHJu43C0SM"></YoutubeModal>
  }>
    {pricing}
  </HtmlTooltip>;
  let donate = <a className="box__link button-animation" target="_blank" href="https://donate.betty.works/">
    Donate
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </a>;
  let tooltipDonate = <HtmlTooltip title={
    <YoutubeModal title="" youtubeId="75IN3ivwjkk"></YoutubeModal>
  }>
    {donate}
  </HtmlTooltip>;

  function getFixturesOverview() {
    const path = window.location.pathname.slice(1);
    const exp = path.split("/");
    if (exp.length > 1 && exp[1] === "export") {
      return <Export token={exp[0]}/>
    } else {
      return <FixturesOverview leagues={leagues} user={user}/>;
    }
  }

  return (
    <Box sx={{display: "flex"}}>
      <TeamStrengthDialog league={league} setOpen={setLeague} user={user}/>
      <LeagueStandingsDialog league={standing} setOpen={setStanding}/>
      <UserDialog
        user={user}
        open={userDialogOpen}
        setOpen={handleSetUserDialogOpen}
        allUsers={allUsers}
        setAllUsers={handleSetAllUsers}
      />
      <CssBaseline/>
      <AppBar position="fixed" open={open}>
        <Toolbar style={{display: "flex", textWrap: "nowrap"}}>
          {/*<IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>*/}
          <a href="#" onClick={() => {
            window.location.reload()
          }}><img src={getLogo()} style={{height: "3em"}} alt="betty"/></a>
          <div>
            {localStorage.getItem("showTutorials") === "true" ? tooltipTeamSettings : teamSettings}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {leagues?.map((league) => (
                <MenuItem onClick={() => handleSelect(league)}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <img
                      style={{width: "1em"}}
                      alt={league.country.data.name}
                      title={league.country.data.name}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        extractFlag(league.country.data.extra)
                      )}`}
                    />
                    {league?.name}
                  </div>
                </MenuItem>
              ))}
            </Menu>
            {localStorage.getItem("showTutorials") === "true" ? tooltipStandings : standings}
            <Menu
              id="basic-menu"
              anchorEl={anchorStandingsEl}
              open={menuStandingsOpen}
              onClose={handleStandingsClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {leagues?.map((league) => (
                <MenuItem onClick={() => handleStandingsSelect(league)}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5em",
                    }}
                  >
                    <img
                      style={{width: "1em"}}
                      alt={league.country.data.name}
                      title={league.country.data.name}
                      src={`data:image/svg+xml;utf8,${encodeURIComponent(
                        extractFlag(league.country.data.extra)
                      )}`}
                    />
                    {league?.name}
                  </div>
                </MenuItem>
              ))}
            </Menu>
            {localStorage.getItem("showTutorials") === "true" ? tooltipHelp : help}
            {localStorage.getItem("showTutorials") === "true" ? tooltipDocs : docs}
            {localStorage.getItem("showTutorials") === "true" ? tooltipPricing : pricing}
            {localStorage.getItem("showTutorials") === "true" ? tooltipDonate : donate}
          </div>
          {" "}
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setUserDialogOpen(true)}
              edge="start"
              sx={{ml: 2, ...(open && {display: "none"})}}
            >
              <SettingsIcon/>
            </IconButton>
            {user?.name}{" "}
            {window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1"
              ? `(${window.location.host})`
              : ""}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon/>
            ) : (
              <ChevronRightIcon/>
            )}
          </IconButton>
        </DrawerHeader>
        <Divider/>
        <List>
          {leagues?.map(({name, country}) => (
            <ListItem key={name}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked/>}
                  label={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      <img
                        style={{width: "1em"}}
                        alt={country.data.name}
                        title={country.data.name}
                        src={`data:image/svg+xml;utf8,${encodeURIComponent(
                          extractFlag(country.data.extra)
                        )}`}
                      />

                      {name}
                    </div>
                  }
                />
              </FormGroup>{" "}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader/>
        {getFixturesOverview()}
      </Main>
    </Box>
  );
}
